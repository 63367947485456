<template>
  <router-view v-if="['Login', 'forgetPassword', 'roadHelp', 'Protector', 'Topdanmarkipid', 'Topdanmarkvilkaar', 'roadassistanceeurope'].includes($route.name)"></router-view>
  <DefaultLayout v-else />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
<script>
import DefaultLayout from "@/layouts/DefaultLayout";
export default {
  components: {DefaultLayout},
  mounted() {
    if (this.$store.getters["auth/isAuthenticated"]){
      this.$store.dispatch('miscs/getStatuses')
      this.$store.dispatch('miscs/getCounter')
    }
  }
}
</script>
